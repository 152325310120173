<template>
  <section class="section-1 bg-block mb-4">
    <h4 v-if="churchName !== ``">{{ churchName }}</h4>
    <h4 v-if="locationName !== ``">{{ locationName }}</h4>
    <h4 v-if="individualName !== ``">{{ individualName }}</h4>
    <h1 class="sm mb-4">{{ i18n['address-form'].tcAddEditAddress }}</h1>
    <div class="body">
      <b-form>
        <b-form-group class="form-element">
          <div class="g-row">
            <div class="g-col-1 d-flex">
              <b-form-group class="form-element">
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['address-form'].tcAddressType }}</h3>
                <b-form-select v-model="editedAddress.adt_key" :options="optionsAddressTypesTranslated"
                  class="form-control g-select flex-0 mr-3 mb-3">
                  <template slot="first"></template>
                </b-form-select>
                <label for="checkbox-1">
                  <h3 class="flex-1 mr-3 text-left font-style-2">
                    {{ i18n['address-form'].tcIsPrimary }}
                  </h3>
                </label>
                <b-form-checkbox id="checkbox-1" v-model="editedAddress.primary_address_flag" name="checkbox-1"
                  :value="checkedValue" class="mb-3" :unchecked-value="uncheckedValue"></b-form-checkbox>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['address-form'].tcAddress1 }}</h3>
                <b-form-input id="type-text6" v-model="editedAddress.address_line1" class="flex-0 mb-3 g-select"
                  type="text" maxlength="75"></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['address-form'].tcAddress2 }}</h3>
                <b-form-input id="type-text7" v-model="editedAddress.address_line2" class="flex-0 mb-3 g-select"
                  type="text" maxlength="75"></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['address-form'].tcCity }}</h3>
                <b-form-input id="type-text8" v-model="editedAddress.city" class="flex-0 mb-3 g-select" type="text"
                  maxlength="40"></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">
                  <span v-if="editedAddress.cnt_key === this.USA_country">
                    {{ i18n['address-form'].tcState }}
                  </span>
                  <span v-else>
                    {{ i18n['address-form'].tcStateOrProvince }}
                  </span>
                </h3>
                <b-form-input id="type-text10" v-if="editedAddress.cnt_key !== this.USA_country"
                  v-model="editedAddress.state" class="flex-0 mb-3 g-select" type="text" maxlength="40"></b-form-input>
                <b-form-select v-if="editedAddress.cnt_key === this.USA_country" v-model="editedAddress.state"
                  :options="optionsStates" class="form-control g-select flex-0 mr-3 mb-3">
                  <template slot="first"></template>
                </b-form-select>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['address-form'].tcZipCode }}</h3>
                <b-form-input id="type-text9" v-model="editedAddress.postal_code" class="flex-0 mb-3 g-select"
                  type="text" maxlength="20"></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['address-form'].tcCountry }}</h3>
                <b-form-select v-model="editedAddress.cnt_key" :options="optionsCountries"
                  class="form-control g-select flex-0 mr-3 mb-3">
                  <template slot="first"></template>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="g-col-2"></div>
          </div>
        </b-form-group>
      </b-form>
    </div>
    <div class="body">
      <div class="d-flex">
        <b-button @click="handleSaveClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
          i18n['address-form'].tcSave
        }}</b-button>
        <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
          i18n['address-form'].tcCancel
        }}</b-button>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'address-form',
  mixins: [translationMixin],
  data() {
    return {
      editedAddress: {
        adr_key: '',
        axe_key: '',
        adt_key: null,
        address_type: null,
        address_line1: '',
        address_line2: '',
        address_line3: '',
        city: '',
        state: null,
        postal_code: '',
        country: 'United States',
        cnt_key: null,
        primary_address_flag: false,
      },
      checkedValue: true,
      uncheckedValue: false,
      optionsAddressTypes: [],
      optionsAddressTypesTranslated: {},
      translations: {},
    }
  },
  props: {
    churchName: {
      type: String,
      default: '',
    },
    churchKey: {
      type: String,
      default: '',
    },
    locationName: {
      type: String,
      default: '',
    },
    locationKey: {
      type: String,
      default: '',
    },
    individualName: {
      type: String,
      default: '',
    },
    individualKey: {
      type: String,
      default: '',
    },
    userKey: {
      type: String,
      default: '',
    },
    address: {
      type: Object,
      default: () => {
        return {
          adr_key: '',
          axe_key: '',
          adt_key: null,
          address_type: null,
          address_line1: '',
          address_line2: '',
          address_line3: '',
          city: '',
          state: null,
          postal_code: '',
          country: null,
          cnt_key: null,
          primary_address_flag: false,
        }
      },
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'address-form': {
            tcAddEditAddress: 'Add/Edit an Address',
            tcAddress1: 'Address 1',
            tcAddress2: 'Address 2',
            tcAddressSaved: 'The address was saved.',
            tcAddressType: 'Address Type',
            tcAddressTypeRequired: 'Address Type is required. Please complete before attempting to save.',
            tcCancel: 'Cancel',
            tcCity: 'City',
            tcCountry: 'Country',
            tcErrorDuringSave: 'An error occurred during the save process. The address was not saved.',
            tcIsPrimary: 'Is Primary?',
            tcOk: 'Ok',
            tcSave: 'Save',
            tcSelectACountry: 'Select a country',
            tcSelectAState: 'Select a state',
            tcSelectAType: 'Select a type',
            tcState: 'State',
            tcStateOrProvince: 'State/Province',
            tcZipCode: 'Zip Code',
          },
        }
      },
    },
  },
  methods: {
    ...mapActions({
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      saveAddress: 'membership/saveAddress',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAddress: 'user/setSelectedAddress',
    }),
    async handleSaveClick() {
      if (this.editedAddress.adt_key === null) {
        this.$swal({
          icon: 'error',
          text: this.i18n['address-form'].tcAddressTypeRequired,
          confirmButtonText: this.i18n['address-form'].tcOk,
        }).then((result) => {
          return
        })
      } else {
        var payload = {
          member_no: this.demograph.member_number,
          adr_key: this.editedAddress.adr_key,
          adr_line1: this.editedAddress.address_line1,
          adr_line2: this.editedAddress.address_line2,
          adr_line3: this.editedAddress.address_line3,
          adr_city: this.editedAddress.city,
          adr_state: this.editedAddress.state,
          adr_postal_code: this.editedAddress.postal_code,
          adr_cnt_key: this.editedAddress.cnt_key,
          adr_business_name: '',
          adr_business_attn: '',
          adr_nag_key: '',
          adr_add_user: this.userKey,
          adr_add_date: new Date(),
          adr_change_user: this.userKey,
          adr_change_date: new Date(),
          adr_delete_flag: false,
          adr_usps_checked_flag: false,
          adr_usps_valid_flag: false,
          adr_usps_corrected_flag: false,
          adr_usps_uncorrected: '',
          axe_key: this.editedAddress.axe_key,
          axe_entity_key: this.setAxeKey(),
          axe_adt_key: this.editedAddress.adt_key,
          allow_confirm_primary_procedure_run: false,
          primary_address_flag: this.isPrimaryFlag,
          post_to_nf: this.isFromStoreCheckout() ? true : false,
        }
        let result = true
        try {
          await Promise.all([this.setLoadingStatus(true), (result = await this.saveAddress(payload))]).then(() => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.i18n['address-form'].tcAddressSaved : this.i18n['address-form'].tcErrorDuringSave,
              confirmButtonText: this.i18n['address-form'].tcOk,
            }).then((result) => {
              if (result.value) {
                this.handleCancelClick()
              }
            })
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async loadOptions() {
      await (async () => {
        if (
          this.addressFormOptions.countries.length === 0 ||
          this.addressFormOptions.addressTypes.length === 0 ||
          this.addressFormOptions.states.length === 0
        ) {
          await this.loadAddressFormOptions()
        }
        if (this.address) {
          this.editedAddress.adr_key = !!this.address.adr_key ? this.address.adr_key : ''
          this.editedAddress.axe_key = !!this.address.axe_key ? this.address.axe_key : ''
          this.editedAddress.adt_key = !!this.address.adt_key ? this.address.adt_key : null
          this.editedAddress.address_type = !!this.address.address_type ? this.address.address_type : null
          this.editedAddress.address_line1 = !!this.address.address_line1 ? this.address.address_line1 : ''
          this.editedAddress.address_line2 = !!this.address.address_line2 ? this.address.address_line2 : ''
          this.editedAddress.address_line3 = !!this.address.address_line3 ? this.address.address_line3 : ''
          this.editedAddress.city = !!this.address.city ? this.address.city : ''
          this.editedAddress.state = !!this.address.state ? this.address.state : null
          this.editedAddress.postal_code = !!this.address.postal_code ? this.address.postal_code : ''
          this.editedAddress.country = !!this.address.country ? this.address.country : 'United States'
          this.editedAddress.cnt_key = !!this.address.cnt_key
            ? this.address.cnt_key
            : this.convertedAddressFormOptions.countriesByCamp[0].value
          this.editedAddress.primary_address_flag = !!this.address.primary_address_flag
        }
      })()
    },
    async handleCancelClick() {
      await Promise.all([await this.setSelectedAddress({})]).then(() => {
        this.$router.go(-1)
      })
    },
    isFromStoreCheckout() {
      return this.$route.name.indexOf('checkout') > -1
    },
    setAxeKey() {
      if (this.individualKey) {
        return this.individualKey
      }
      if (this.churchKey) {
        return this.churchKey
      }
      if (this.locationKey) {
        return this.locationKey
      }
      return null
    },
  },
  computed: {
    ...mapGetters({
      addressFormOptions: 'membership/addressFormOptions',
      churchCommunicationsData: 'churchMinistry/churchCommunicationsData',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      demograph: 'user/demograph',
      individualCommunicationsData: 'membership/individualCommunicationsData',
      locationCommunicationsData: 'scriptureDistribution/locationCommunicationsData',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
    }),
    isPrimaryFlag() {
      return this.editedAddress.primary_address_flag === true ||
        (
          this.individualName.length > 0 &&
          this.individualCommunicationsData.addresses &&
          this.individualCommunicationsData.addresses.length === 0
        ) ||
        (
          this.churchName.length > 0 &&
          this.churchCommunicationsData.addresses &&
          this.churchCommunicationsData.addresses.length === 0
        ) ||
        (
          this.locationName.length > 0 &&
          this.locationCommunicationsData.addresses &&
          this.locationCommunicationsData.addresses.length === 0
        )
    },
    // FormOptions
    optionsCountries() {
      return [
        { value: null, text: this.i18n['address-form'].tcSelectACountry, disabled: true },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    optionsStates() {
      return [
        { value: null, text: this.i18n['address-form'].tcSelectAState, disabled: true },
        ...this.convertedAddressFormOptions.states,
      ]
    },
    // END FormOptions
    USA_country() {
      return constantData.USA_country
    },
  },
  async created() {
    try {
      await this.setLoadingStatus(true)
      await this.loadOptions()
      await Promise.all([this.getComponentTranslations('common.address-types')]).then((results) => {
        this.optionsAddressTypes = this.convertedAddressFormOptions.addressTypes
        this.stripReadableText(results[0])
        this.translateDropdownOptions([
          {
            node: 'address-types',
            store: 'optionsAddressTypes',
            disabledPlaceholder: this.i18n['address-form'].tcSelectAType || 'Select a Type',
          },
        ])
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
